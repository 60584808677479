import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {AlertModule} from "ngx-bootstrap/alert";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {ModalModule} from "ngx-bootstrap/modal";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {TabsModule} from "ngx-bootstrap/tabs";
import {CasualDashboardComponent} from "./pages/dashboard/dashboard.component";
import {HeaderComponent} from "./@pages/components/header/header.component";
import {HorizontalMenuComponent} from "./@pages/components/horizontal-menu/horizontal-menu.component";
import {pgCardSocialModule} from "./@pages/components/card-social/card-social.module";
import {CasualLayout} from "./@pages/layouts/casual/casual.component";
import {RootLayout} from "./@pages/layouts/root/root.component";
import {RouterModule} from "@angular/router";
import {pagesToggleService} from "./@pages/services/toggler.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SearchOverlayComponent} from "./@pages/components/search-overlay/search-overlay.component";
import {SharedModule} from "./@pages/components/shared.module";
import {pgTagModule} from "./@pages/components/tag/tag.module";
import {ResultsComponent} from "./pages/results/results.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {EventComponent} from "./pages/events/event.component";
import {CoverpageDirective} from "./pages/events/coverpage.directive";
import {NgsRevealModule} from "ng-scrollreveal";
import {UserService} from "./services/user.service";
import {AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule} from "angularx-social-login";
import {TokenService} from "./services/token.service";
import {AuthenticationInterceptor} from "./services/authentication.interceptor";
import {NonstopTranslateService} from "./services/translate.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {EventResource} from "./resources/event.resource";
import {LoaderService} from "./services/loader.service";
import {MyComponent} from "./pages/my/my.component";
import {MyResultComponent} from "./pages/my/result/my.result.component";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {TrackResource} from "./resources/track.resource";
import {CountdownModule} from "ngx-countdown";
import {EventService} from "./services/event.service";
import {pgSelectModule} from "./@pages/components/select/select.module";
import {pgDatePickerModule} from "./@pages/components/datepicker/datepicker.module";
import {ValidationService} from "./services/validation.service";
import {LiveComponent} from "./pages/live/live.component";

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('847434248477-dnpb30p2mg89vpb1meq1gk5ngjun6f5n.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("559556668086498")
  }
]);

export function provideConfig() {
  return config;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CasualDashboardComponent,
    ResultsComponent,
    EventComponent,
    MyComponent,
    LiveComponent,
    MyResultComponent,
    CasualLayout,
    RootLayout,
    HeaderComponent,
    HorizontalMenuComponent,
    SearchOverlayComponent,
    CoverpageDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    pgCardSocialModule,
    SharedModule,
    TabsModule.forRoot(),
    pgTagModule,
    pgSelectModule,
    pgDatePickerModule,
    NgxDatatableModule,
    NgsRevealModule.forRoot(),
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LeafletModule,
    CountdownModule
  ],
  providers: [
    /**
     * Сервисы
     */
    pagesToggleService,
    LoaderService,
    TokenService,
    UserService,
    NonstopTranslateService,
    EventService,
    ValidationService,
    /**
     * Ресурсы
     */
    EventResource,
    TrackResource,

    [
      {
        provide: AuthServiceConfig,
        useFactory: provideConfig
      }
    ],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
