import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {CookieService} from "ngx-cookie-service";
import {Observable} from "rxjs";


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // console.log(localStorage.getItem('lang'));

        let token = this.cookieService.get('token');
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                'Content-Language': localStorage.getItem('lang')
            }
        });
        return next.handle(request);
    }
}
