import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {EventResource} from "../../resources/event.resource";

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent {
  
  live = [];
  private eventId: any;
  

  constructor(private route: ActivatedRoute,
              private eventResource: EventResource) {
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      this.live = await this.eventResource.live(this.eventId) as Array<any>;
      
    });
  }
  
}
