import { Routes } from '@angular/router';
import {CasualLayout} from "./@pages/layouts/casual/casual.component";
import {CasualDashboardComponent} from "./pages/dashboard/dashboard.component";
import {ResultsComponent} from "./pages/results/results.component";
import {EventComponent} from "./pages/events/event.component";
import {MyComponent} from "./pages/my/my.component";
import {MyResultComponent} from "./pages/my/result/my.result.component";
import {LiveComponent} from "./pages/live/live.component";

export const AppRoutes: Routes = [
    {
        path: '',
        data: { breadcrumb: 'Home' },
        component: CasualLayout,
        children: [
          {
            path: '',
            component: CasualDashboardComponent,
            data: {
              layoutOption: 'dashboard'
            }
          },
          {
            path: 'results',
            component: ResultsComponent,
          },
          {
            path: 'event/:eventId',
            component: EventComponent,
          },
          {
            path: 'my',
            component: MyComponent,
          },
          {
            path: 'my/:trackId',
            component: MyResultComponent,
          },
          {
            path: 'live/:eventId',
            component: LiveComponent,
          }

        ]
    },
    {
        path: '**',
        redirectTo: 'error/404'
    },
];
