import {EventEmitter, Injectable} from "@angular/core";
import {TokenService} from "./token.service";
import {CookieService} from "ngx-cookie-service";
import {AuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser} from "angularx-social-login";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config/AppConfig";

@Injectable()
export class UserService {
  private oAuthSub = null;
  constructor(private cookieService: CookieService,
              private tokenService: TokenService,
              public httpClient: HttpClient,
              private authService: AuthService) {
  }

  private user: any = null;

  private userEmitter: EventEmitter<any> = new EventEmitter();

  public getUserEmitter() {
    return this.userEmitter;
  }


  public init() {
    if (!this.isAuth()) {
      this.user = null;

      return;
    }

    this.tokenService.setToken(this.cookieService.get('token'));

    // this.profileResource.getMe().$observable.subscribe(
    //   (response: any) => {
    //     this.user = response;
    //     this.pushService.connectToChannelPushServer(this.pushService.userPrefix + this.user.id);
    //     this.userEmitter.emit(this.user);
    //   },
    //   (response: Response) => {
    //     if (response.status == 401 && response.json().message == 'User not activated') {
    //       this.router.navigate(['/register/complete']);
    //       return;
    //     }
    //
    //     if (response.status == 401) {
    //       this.logout();
    //       this.router.navigate(['/login']);
    //     }
    //   }
    // );
  }

  public isAuth() {
    return !!this.cookieService.get('token');
  }

  public getUser() {
    return this.user;
  }

  public logout() {
    this.tokenService.setToken(null);
    this.cookieService.delete('token', '/', '.nonstop.run');
    this.init();
  }

  public setToken(token) {
    let expires = new Date();
    expires.setDate(expires.getDate() + 14);

    this.tokenService.setToken(token);
    if (this.cookieService.get('token')) {
      this.cookieService.delete('token', '/', '.nonstop.run');
    }
    this.cookieService.set('token', token, expires, '/', '.nonstop.run');
  }

  public getToken() {
    this.tokenService.getToken();
  }

  signInSocial(provider) {
    this.authService.signIn(provider).then((user) => {
      this.loginWithSocial(user);
    }).catch((error) => {
      this.userEmitter.emit({type: 'error', message: ''});
    });
  }

  private loginWithSocial(user) {
    const sub = this.httpClient.put(AppConfig.apiUrl + '/user/social/', user).subscribe((response: any) => {
        console.log(response);
        this.setToken(response.token);
        this.me();
        sub.unsubscribe();
      },
      (error: any) => {
        this.userEmitter.emit({type: 'error', message: ''});
        sub.unsubscribe();
      });
  }

  public me() {
    const sub = this.httpClient.get(AppConfig.apiUrl + '/user/me/').subscribe((response: any) => {
      this.user = response;
        this.userEmitter.emit({type: 'update', message: this.user});
      sub.unsubscribe();
    },
      (error: any) => {
      this.logout();
      sub.unsubscribe();
    });
  }

  public getAvatar() {
    if (this.user !== null) {
      return AppConfig.apiUrl + this.user.profile.avatar;
    }
  }
}
