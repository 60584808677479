<pg-header extraClass="bg-primary p-r-0">
  <div class="header-inner header-md-height">
    <!-- START MOBILE SIDEBAR TOGGLE -->
    <a href="javascript:void(0);" class="btn-icon-link header-icon toggle-sidebar d-lg-none" (click)="toggleHorizontalMenuMobile()">
      <i class="pg-icon">menu</i>
    </a>
    <!-- END MOBILE SIDEBAR TOGGLE -->
    <div class="">
      <div class="brand inline no-border d-sm-inline-block">
        <img src="assets/img/nonstop2.png" alt="logo" pgRetina src1x="assets/img/nonstop2.png"
          src2x="assets/img/nonstop2.png" height="35">
      </div>
      <!-- START EMAIL MOBILE TOGGLE -->
      <a href="javascript:void(0);" class="toggle-secondary-sidebar align-items-center"
        (click)="toggleSecondarySideBar()" *ngIf="_layoutOption === 'email'">
        <span class="d-flex align-items-center">
          Inbox <span class="text-info">(12)</span> <span class="pg-icon">drop_down</span>
        </span>
      </a>
      <!-- END EMAIL MOBILE TOGGLE -->
      <!-- START NOTIFICATION LIST -->
      <ul class="d-lg-inline-block d-none notification-list no-margin  b-grey b-l b-r no-style p-l-0 p-r-20">
        <ng-template [ngIf]="_layoutOption != 'email'">
          <li class="p-r-10 inline">
            <a href="javascript:void(0)" class="header-icon btn-icon-link">
              <i class="pg-icon">link_alt</i>
            </a>
          </li>
          <li class="p-r-10 inline">
            <a href="javascript:void(0)" class="header-icon btn-icon-link">
              <i class="pg-icon">grid_alt</i>
            </a>
          </li>
        </ng-template>
      </ul>
      <!-- END NOTIFICATIONS LIST -->

    </div>
    <div class="d-flex align-items-center" *ngIf="!userService.isAuth()">
      <div class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none text-white">
        <button type="button" class="btn btn-outline-dark btn-sm" (click)="showLogin()">{{translateService.t('Login | Registration')}}</button>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="userService.isAuth()">
      <!-- START User Info-->
      <div class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none text-white">
        <span class="semi-bold">{{getFirstName()}}</span> <span class="">{{getLastName()}}</span>
      </div>
      <div class="dropdown pull-right d-lg-block d-none" dropdown>
        <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
          aria-label="profile dropdown">
          <span class="thumbnail-wrapper d32 circular inline">
            <img src="{{userService.getAvatar()}}" alt="" pgRetina src1x="{{userService.getAvatar()}}"
              src2x="{{userService.getAvatar()}}" width="32" height="32">
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
          <a href="#" class="dropdown-item"><span>{{"Signed in as" | translate}} <br /><b>{{getFirstName()}} {{getLastName()}}</b></span></a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">{{"My Events" | translate}}</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" (click)="userService.logout()">{{"Logout" | translate}}</a>
        </div>
      </div>
      <!-- END User Info-->
    </div>
  </div>
  <div class="bg-white">
    <div class="container">
      <pg-horizontal-menu [Items]="menuItems" [AutoHideItems]="false" (click)="menuClick($event)">
        <ng-template #mobileSidebarFooter>
          <a href="javascript:void(0)" class="search-link d-flex justify-content-between align-items-center d-lg-none"
            (click)="openSearch($event)">Tap here to search <i class="pg pg-search float-right"></i></a>
        </ng-template>
      </pg-horizontal-menu>
    </div>
  </div>
</pg-header>
<section [ngClass]="loading ? '' : 'loading-hide'" class="col-lg-12 loader">
  <img src="assets/img/progress.svg">
</section>
<div class="page-container ">
  <!-- START PAGE CONTENT WRAPPER -->
  <div class="page-content-wrapper {{_pageContainerClass}}">
    <!-- START PAGE CONTENT -->
    <div class="content {{_contentClass}}">
      <router-outlet></router-outlet>
    </div>
    <!-- END PAGE CONTENT -->
    <!-- START COPYRIGHT -->
    <!-- START CONTAINER FLUID -->
    <ng-template [ngIf]="_footer">
      <div class=" container   container-fixed-lg footer">
        <div class="copyright sm-text-center">
          <p class="small no-margin pull-left sm-pull-reset">
            <span class="hint-text">&copy; 2020 </span>
            &nbsp;<span class="font-montserrat">Nonstop.run</span>.&nbsp;
            <span class="hint-text">{{"All rights reserved" | translate}}. </span>
            <span class="sm-block">
              <a href="javascript:void(0)" class="m-l-10 m-r-10">{{"Terms of use" | translate}}</a>
              <span class="muted">|</span>
              <a href="javascript:void(0)" class="m-l-10">{{"Privacy Policy" | translate}}</a>
            </span>
          </p>
          <span class="sm-block no-margin pull-right sm-pull-reset">
            <button (click)="translateService.setCurrentLang('ru')" class="btn-sm btn btn-link">Русский</button> |
            <button (click)="translateService.setCurrentLang('en')" class="btn btn-link">English</button>
          </span>
          <div class="clearfix"></div>
        </div>
      </div>
    </ng-template>
    <!-- END COPYRIGHT -->
  </div>
  <!-- END PAGE CONTENT WRAPPER -->
</div>
<app-search-overlay></app-search-overlay>

<div bsModal #mdStickUp="bs-modal" class="modal fade stick-up" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header clearfix text-left">
        <button type="button" class="close" (click)="mdStickUp.hide()" aria-hidden="true"><i class="pg-icon">close</i>
        </button>
        <h5>Login or Registration</h5>
        <p>Press on one of the buttons below</p>
      </div>
      <div class="modal-body">
        <p>And follow the instructions.</p>
        <br>
        <button aria-label="" class="btn btn-block btn-complete btn-lg" type="button" (click)="signInWithFB()">
          <i class="pg-icon">facebook</i>
          <span>With Facebook</span>
        </button>
        <button aria-label="" class="btn btn-block btn-danger btn-lg" type="button" (click)="signInWithGoogle()">
          <i class="pg-icon">google_plus</i>
          <span>With Google</span>
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>



