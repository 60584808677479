import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {RootLayout} from "../root/root.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {pagesToggleService} from "../../services/toggler.service";
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {FacebookLoginProvider, GoogleLoginProvider} from "angularx-social-login";
import {NonstopTranslateService} from "../../../services/translate.service";
import {AppConfig} from "../../../config/AppConfig";
import {LoaderService} from "../../../services/loader.service";
declare var pg: any;
@Component({
  selector: 'casual-layout',
  templateUrl: './casual.component.html',
  styleUrls: ['./casual.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CasualLayout extends RootLayout implements OnInit, OnDestroy {

  @ViewChild('mdStickUp', { static: true }) mdStickUp: ModalDirective;

  menuItems = [
    {
      label: 'Events',
      details: '12 New Updates',
      routerLink: '/',
      iconType: 'pg',
      iconName: 'home'
    },
    {
      label: 'Results',
      routerLink: '/results',
      iconType: 'pg',
      iconName: 'layouts'
    },
    {
      label: 'My Events',
      routerLink: '/my',
      iconType: 'pg',
      iconName: 'layouts'
    },
    // {
    //   label: 'Мой раздел',
    //   iconType: 'letter',
    //   iconName: 'Ui',
    //   toggle: 'close',
    //   mToggle: 'close',
    //   submenu: [
    //     {
    //       label: 'Мои забеги',
    //       routerLink: 'ui/color',
    //       iconType: 'letter',
    //       iconName: 'c'
    //     },
    //     {
    //       label: 'Мои результаты',
    //       routerLink: 'ui/typography',
    //       iconType: 'letter',
    //       iconName: 't'
    //     }
    //   ]
    // },

  ];
  public loading = true;

  constructor(public toggler: pagesToggleService,
              public router: Router,
              public userService: UserService,
              private loaderService: LoaderService,
              public translateService: NonstopTranslateService) {
    super(toggler, router);
    this.loaderService.isLoading.subscribe((isLoading) => {
      if (this.loading !== isLoading) {
        this.loading = isLoading;
      }
    });
  }

  ngOnInit() {
    pg.isHorizontalLayout = true;
    this.changeLayout('horizontal-menu');
    this.changeLayout('horizontal-app-menu');
    this.subscribeToUserService();
    if (this.userService.isAuth()) {
      this.userService.me();
    }

  }

  private subscribeToUserService() {
    this.userService.getUserEmitter().subscribe((emitter) => {
      // if (emitter.type === 'error') {
        this.hideLogin();
      // }
    })
  }

  menuClick($event: MouseEvent) {
    console.log($event);
  }

  public showLogin() {
    this.mdStickUp.show();
  }

  public hideLogin() {
    this.mdStickUp.hide();
  }

  signInWithGoogle(): void {
    this.userService.signInSocial(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.userService.signInSocial(FacebookLoginProvider.PROVIDER_ID);
  }

  getFirstName() {
    if (this.userService.getUser() !== null) {
      return this.userService.getUser().profile.first_name;
    }
  }

  getLastName() {
    if (this.userService.getUser() !== null) {
      return this.userService.getUser().profile.last_name;
    }
  }
}
