import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../../services/loader.service";
import {circle, icon, latLng, marker, polygon, polyline, tileLayer} from "leaflet";
import {TrackResource} from "../../../resources/track.resource";
import {CasualLayout} from "../../../@pages/layouts/casual/casual.component";

@Component({
  selector: 'app-my-result',
  templateUrl: './my.result.component.html',
  styleUrls: ['./my.result.component.scss']
})
export class MyResultComponent implements OnInit {
  public events: Array<any> = [];

  public options = {};
  public layers = [];
  public showMap = false;

  private trackId: any;

  constructor(private router: Router,
              private loaderService: LoaderService,
              private route: ActivatedRoute,
              private layout: CasualLayout,
              private trackResource: TrackResource) {
    this.layout.loading = true;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.trackId = params['trackId'];
      const observable = this.trackResource.byId(this.trackId);
      const sub = observable.subscribe(track => {
        this.drawTrack(track);
        sub.unsubscribe();
      }, error => {
        console.log(error);
        sub.unsubscribe();
      })
    });
  }

  public drawTrack(track) {
    this.options = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'testoid.io' })
      ],
      zoom: 16,
      center: latLng(43.406004, 39.954997)
    };
    const p = polyline(track.points, {color: 'red'});
    p.getBounds();

    const lastPoint = track.points.shift();

    console.log(lastPoint);

    const runner = marker(lastPoint, {
      icon: icon({
        iconSize: [25, 25],
        iconAnchor: [25, 25],
        shadowSize: [25, 25],
        iconUrl: 'assets/runner.png',
        shadowUrl: 'assets/marker-shadow.png',
      }),
    });

    this.layers = [p, runner];
    this.showMap = true;
    this.layout.loading = false;
  }

}
