import {EventEmitter, Injectable} from '@angular/core';
import {Observable, Observer} from "rxjs";

@Injectable()
export class TokenService {
    public observable: Observable<any> = null;
    public emitter: EventEmitter<any> = null;

    constructor() {
        this.emitter = new EventEmitter(true);
    }

    private token: string = null;

    public tokenObservable(): Observable<boolean> {
        if (this.observable) {
            return this.observable;
        }

        this.observable = new Observable((observer: Observer<any>) => this.tokenSubscriber(observer));

        return this.observable;
    }

    private tokenSubscriber(observer: Observer<any>) {
        this.emitter.subscribe(
            () => {
                observer.next(this.token);
            }
        );
    }

    public setToken(token): any {
        this.token = token;

        this.emitter.emit();
    }

    public getToken(): string {
        return this.token;
    }
}
