<pg-container extraHorizontalClass="bg-white" extraClass="bg-white">
  <h4>Live</h4>
  <div *ngFor="let r of live">
    <span style="padding-right: 20px">{{r.distance}} м</span>
    <span style="width: 100px">{{r.runner}}</span>

  </div>
</pg-container>
<!-- START CONTAINER FLUID -->
<!--  <pg-container extraHorizontalClass="bg-white" extraClass="bg-white">-->
<!--      &lt;!&ndash; START card &ndash;&gt;-->
<!--      <div class="card card-transparent">-->
<!--        <div class="card-header  d-flex justify-content-between">-->
<!--          <div class="card-title">Table with Fixed and lazy scrolling-->
<!--          </div>-->
<!--          <div class="export-options-container"></div>-->
<!--          &lt;!&ndash; <div class="clearfix"></div> &ndash;&gt;-->
<!--        </div>-->
<!--        <div class="card-body">-->
<!--            <div class="table-responsive"  style="height:600px;overflow-y: hidden">-->
<!--              <ngx-datatable-->
<!--              #tableAdvance-->
<!--              class='table table-hover table-stripped'-->
<!--              [columns]="advanceColumns"-->
<!--              [columnMode]="columnModeSetting"-->
<!--              [scrollbarH]="scrollBarHorizontal"-->
<!--              [headerHeight]="50"-->
<!--              [footerHeight]="50"-->
<!--              [rowHeight]="60"-->
<!--              [limit]="5"-->
<!--              [scrollbarV]="true"-->
<!--              [rows]='advanceRows'>-->
<!--              </ngx-datatable>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      &lt;!&ndash; END card &ndash;&gt;-->
<!--  </pg-container>-->
  <!-- END CONTAINER FLUID -->

