<!-- START JUMBOTRON -->
<div class="jumbotron">
  <pg-container extraHorizontalClass="p-l-0 p-r-0" extraClass="sm-p-l-0 sm-p-r-0">
    <div class="inner" pg-parallax>
      <!-- START BREADCRUMB -->
      <pg-breadcrumb>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
          <li class="breadcrumb-item active">Form Elements</li>
        </ol>
      </pg-breadcrumb>
      <!-- END BREADCRUMB -->
      <div class="row">
        <div class="col-xl-7 col-lg-6 ">
          <!-- START card -->
          <div class="card card-default">
            <div class="card-body text-center">
              <img class="image-responsive-height demo-mw-600" src="assets/img/demo/form_hero.gif" alt="">
            </div>
          </div>
          <!-- END card -->
        </div>
        <div class="col-xl-5 col-lg-6 ">
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-header ">
              <div class="card-title">Начни прямо сейчас
              </div>
            </div>
            <div class="card-body">
              <h3>Зарегистрируйся на виртуальный забег. <br>Пробеги в реальном времени с другими участниками</h3>
              <br>
              <button aria-label="" type="button" class="btn btn-outline-primary btn-lg btn-larger">Зарегистрироваться бесплатно</button>
            </div>
          </div>
          <!-- END card -->
        </div>
      </div>
    </div>
  </pg-container>
</div>
<!-- END JUMBOTRON -->
<!-- START CONTAINER FLUID -->
<pg-container>
  <div class="row">
    <div class="col-lg-12">
      <!-- START card -->
      <div style="height: 500px;width: 100%" leaflet [leafletOptions]="options" [leafletLayers]="layers" *ngIf="showMap">
      </div>
      <!-- END card -->
    </div>
  </div>
</pg-container>
<!-- END CONTAINER FLUID -->

