import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config/AppConfig";
import {CountdownEvent} from "ngx-countdown";

@Injectable()
export class EventService {
  private _events = new BehaviorSubject([]);
  private dataStore = {events: []};
  // readonly events = this._events.asObservable();

  constructor(private httpClient: HttpClient) {}

  get events() {
    return this._events.asObservable();
  }

  startEvent($event: CountdownEvent, events, index) {
    if ('done' === $event.action) {
      events[index].status = 2;
    }
  }

  loadAll() {
    const observable = this.httpClient.get<any>(AppConfig.apiUrl + '/event/')
    const sub = observable.subscribe(
      data => {
        this.dataStore.events = data.items;
        this._events.next(Object.assign({}, this.dataStore).events);
        sub.unsubscribe();
      },
      error => {
        console.log('Could not load events');
        sub.unsubscribe();
      }
    );
    return observable;
  }

  load(id, userId) {
    const observable = this.httpClient.get<any>(`${AppConfig.apiUrl}/event/${id}/?user_id=${userId}`);
    const sub = observable.subscribe(
      data => {
        let found = false;
        this.dataStore.events.forEach((item, index) => {
          if (item.id === data.id) {
            this.dataStore.events[index] = data;
            found = true;
          }
        });
        if (!found) {
          this.dataStore.events.push(data);
        }
        this._events.next(Object.assign({}, this.dataStore).events);
        sub.unsubscribe();
      },
      error => {
        console.log('Could not load event');
        sub.unsubscribe();
      }
    );
  }

}
