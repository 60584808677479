import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config/AppConfig";

@Injectable()
export class EventResource {
  constructor(public httpClient: HttpClient) {
  }

  public list() {
    return this.httpClient.get(AppConfig.apiUrl + '/event/');
  }

  public my() {
    return this.httpClient.get(AppConfig.apiUrl + '/event/my/');
  }

  public registration(data) {
    return this.httpClient.post(AppConfig.apiUrl + '/event/registration/', data);
  }
  
  public async live(eventId) {
    return await this.httpClient.get(AppConfig.apiUrl + '/live/' + eventId + '/').toPromise();
  }
}
