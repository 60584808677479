import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LoaderService {
    private counter: number = 0;
    public isLoading = new BehaviorSubject<any>('');

    constructor(private router: Router) {
    }

    public add(query: Observable<any>): void {
        this.counter++;
        this.isLoading.next(this.counter !== 0);

        query.subscribe(
            () => {
                this.counter--;
                this.isLoading.next(this.counter !== 0);
            },
            (error) => {
                if (error.status === 403 || error.status === 404) {
                    this.router.navigate(['']);
                }
                this.isLoading.next(this.counter !== 0);

                this.counter--;
            }
        );
    }
}
