import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import moment from "moment";
import { animate, state, style, transition, trigger } from '@angular/animations';

declare var stepsForm: any;
declare var pg: any;

import { IsotopeOptions } from 'ngx-isotope';
import {ActivatedRoute} from "@angular/router";
import {EventService} from "../../services/event.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {CasualLayout} from "../../@pages/layouts/casual/casual.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {EventResource} from "../../resources/event.resource";
import {ValidationService} from "../../services/validation.service";
import {NonstopTranslateService} from "../../services/translate.service";
import {CountdownEvent} from "ngx-countdown";


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('enterAnimation', [
      state(
        'loading',
        style({
          opacity: '0',
          transform: 'translateY(8%)'
        })
      ),
      state(
        'ready',
        style({
          opacity: '1',
          transform: 'translateY(0)'
        })
      ),
      transition('loading => ready', animate('300ms cubic-bezier(0.1, 0.0, 0.2, 1)'))
    ])
  ]
})
export class EventComponent implements OnInit {
  feed = [];
  _isLoading: boolean = true;
  _mode: boolean = true;
  private eventId: number;
  private _event: Observable<any>;
  public event = {
    id: 0,
    status: 0,
    left_time: 0,
    title: '',
    sub_title: '',
    description: '',
    distances: [],
    user_distance: null
  };

  public user = {
    email: '',
    profile: {
      avatar: '',
      first_name: '',
      last_name: ''
    }
  };

  constructor(private route: ActivatedRoute,
              public layout: CasualLayout,
              private formBuilder: FormBuilder,
              public userService: UserService,
              public translateService: NonstopTranslateService,
              private eventResource: EventResource,
              public eventService: EventService) {
    this.layout.loading = true;
  }

  @Input()
  set mode(value) {
    this._mode = value;
  }

  public myOptions: IsotopeOptions = {
    itemSelector: '.card',
    masonry: {
      columnWidth: 300,
      gutter: 20,
      fitWidth: true
    }
  };

  public registrationForm = this.formBuilder.group({
    birthday: ['', [Validators.required, ValidationService.ageValidator]],
    distance: ['', Validators.required],
  });

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.eventId = params['eventId'];
      this._event = this.eventService.events.pipe(
        map(
          events => events.find(item => item.id == this.eventId)
        )
      )

      const sub = this._event.subscribe((item) => {
        const a = 1;
        if (item) {
          this.event = item;
          console.log(item);
          // this.setValueForm()
          this.layout.loading = false;
          sub.unsubscribe();
        }
      })

      if (this.userService.getUser() === null) {
        this.userService.getUserEmitter().subscribe(data => {
          this.setValueForm(data.message);
        });
      } else {
        this.setValueForm(this.userService.getUser());
      }

    });
    // this.mode = pg.isHorizontalLayout;
  }

  registration({value, valid}) {
    this.registrationForm.markAllAsTouched();
    if (!valid) {
      return;
    }

    const data = {
      event_id: this.event.id,
      birthday: value.birthday,
      distance_id: value.distance,
    };

    const sub = this.eventResource.registration(data).subscribe(
      response => {
        console.log(response);
        sub.unsubscribe();
      },
      error => {
        console.log(error);
        sub.unsubscribe();
      }
    );

  }

  public setValueForm(user) {
    this.user = user;
    this.registrationForm.controls['birthday'].setValue(user.profile.birthday);

    this.eventService.load(this.eventId, user.id);
  }

  getBirthdayError(error) {
    if (error.invalidAge) {
      return this.translateService.t('You must be over 18');
    }
    if (error.required) {
      return this.translateService.t('The birthday cannot be empty');
    }
    return null;
  }

  formatDate(date_created: any) {
    return this.translateService.formatDateByCurrentLocale(date_created);
  }

  startEvent($event: CountdownEvent) {
    if ('done' === $event.action) {
      this.event.status = 2;
    }
  }
}
