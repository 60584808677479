<!-- START JUMBOTRON -->
<div class="jumbotron">
  <pg-container extraHorizontalClass="p-l-0 p-r-0" extraClass="sm-p-l-0 sm-p-r-0">
    <div class="inner" pg-parallax>
      <!-- START BREADCRUMB -->
      <pg-breadcrumb>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
          <li class="breadcrumb-item active">Form Elements</li>
        </ol>
      </pg-breadcrumb>
      <!-- END BREADCRUMB -->
      <div class="row">
        <div class="col-xl-7 col-lg-6 ">
          <!-- START card -->
          <div class="card card-default">
            <div class="card-body text-center">
              <img class="image-responsive-height demo-mw-600" src="assets/img/ios_app.png" alt="">
            </div>
          </div>
          <!-- END card -->
        </div>
        <div class="col-xl-5 col-lg-6 ">
          <!-- START card -->
          <div class="card card-transparent">
            <div class="card-header ">
              <div class="card-title">{{"Start now" | translate}}
              </div>
            </div>
            <div class="card-body">
              <h3>{{"Sign up for a virtual race" | translate}} <br>{{"Real-time runs with other runners" | translate}}</h3>
              <br>
              <button aria-label="" type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="openPopUp()">{{"Free registration" | translate}}</button>
            </div>
          </div>
          <!-- END card -->
        </div>
      </div>
    </div>
  </pg-container>
</div>
<!-- END JUMBOTRON -->
<!-- START CONTAINER FLUID -->
<pg-container>
  <div class="row">
    <div class="col-lg-6" *ngFor="let event of events; index as i">
      <!-- START card -->
      <div class="card no-border m-1">
        <div class="padding-15">
          <div class="item-header clearfix">
            <div class="inline">
              <p class="no-margin">
                <strong>{{event.title}}</strong>
              </p>

              <p class="no-margin hint-text">{{event.sub_title}}</p>
            </div>
          </div>
        </div>
        <hr class="no-margin">
        <div>
          <div style="height: 150px; overflow: hidden; display: flex; justify-content: center; align-items: center;">
            <img src="{{event.cover}}" style="height: auto; width: 100%;">
          </div>
        </div>
        <div class="padding-15">
          <p>{{event.description}}</p>

          <div style="padding: 20px 0" *ngIf="event.status === 1">
            {{"start over" | translate}} <countdown [config]="{leftTime: event.left_time, format: translateService.formatCountDown(event.left_time)}" (event)="eventService.startEvent($event, events, i);"></countdown>
          </div>
          <button type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="openEvent(event.id)">{{"Free registration" | translate}}</button>

        </div>
      </div>
      <!-- END card -->
    </div>
  </div>
</pg-container>
<!-- END CONTAINER FLUID -->

