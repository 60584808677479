import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CasualLayout} from "../../@pages/layouts/casual/casual.component";
import {EventResource} from "../../resources/event.resource";
import {LoaderService} from "../../services/loader.service";
import {EventService} from "../../services/event.service";
import {Observable} from "rxjs";
import {CountdownEvent} from "ngx-countdown";
import {$e} from "codelyzer/angular/styles/chars";
import {NonstopTranslateService} from "../../services/translate.service";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class CasualDashboardComponent implements OnInit {
  public events: Array<any> = [];
  private eventsObs: Observable<any[]>;

  constructor(private router: Router,
              private layout: CasualLayout,
              private loaderService: LoaderService,
              public translateService: NonstopTranslateService,
              public eventService: EventService,
              private eventResource: EventResource) {
    // this.layout.loading = true;
    // this.getEvents();
  }

  ngOnInit() {
    this.eventsObs = this.eventService.events;
    // this.loaderService.add(this.eventsObs);
    const sub = this.eventsObs.subscribe((events) => {
      if (events.length) {
        // console.log('data', events);
        this.events = events;
        // this.layout.loading = false;
        sub.unsubscribe();
      }

    });
    this.loaderService.add(this.eventService.loadAll());
  }

  // private getEvents() {
  //   const observable = this.eventResource.list();
  //   const sub = observable.subscribe(
  //     (response: any) => {
  //       this.events = response.items;
  //       console.log(response);
  //       sub.unsubscribe();
  //     },
  //     (error: any) => {
  //       sub.unsubscribe();
  //     });
  //   this.loaderService.add(observable);
  // }

  openEvent(id) {
    this.router.navigate(['/event/' + id]);
  }
  openPopUp() {
    this.layout.showLogin();
  }
}
