import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
// @ts-ignore
import ru from '../../assets/i18n/ru.json';

@Injectable()
export class NonstopTranslateService {

    constructor(private translate: TranslateService) {
        this.initLanguage();
        console.log('init lang');
    }

    private initLanguage() {

        let lang = localStorage.getItem('lang');

        if(!lang) {
            const browserLang = this.translate.getBrowserLang();
            lang = browserLang.match(/en|ru/) ? browserLang : 'ru';
            location.reload(true);
        }

        this.translate.addLangs(['en', 'ru']);
        this.translate.setDefaultLang('en');

        this.setCurrentLang(lang);
    }

    public setCurrentLang(lang: string) {
      this.translate.use(lang);
      moment.locale(lang);
      localStorage.setItem('lang', lang);
      // location.reload(true);
      return false;
    }

    public getCurrentLang(): string {
        return this.translate.currentLang;
    }

    public t(key) {
        if (this.getCurrentLang() == 'ru') {
            return ru[key];
        }
        return key;
    }

    public formatDateByCurrentLocale(date) {
      return moment(date).format('L');
    }

    public formatCountDown(leftTime) {
      const d =(this.getCurrentLang() === 'en') ? '' : 'дней';
      return (leftTime > 86400 ) ? 'dd ' + d + ' HH:mm:ss.S' : 'HH:mm:ss.S';
    }
}
