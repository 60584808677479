import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AppConfig} from "../config/AppConfig";

@Injectable()
export class TrackResource {
  constructor(public httpClient: HttpClient) {
  }

  public byId(id) {
    return this.httpClient.get(AppConfig.apiUrl + '/track/' + id + '/');
  }
}
