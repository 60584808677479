<!-- START CONTAINER FLUID -->
<pg-container>
  <div class="row" style="margin-top: 15px" *ngIf="!userService.isAuth()">
    <div class="col-lg-12">
      <p>Вы не авторизованы.</p>
      <button type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="layout.showLogin()">Войти</button>
    </div>
  </div>
  <div class="row" style="margin-top: 15px" *ngIf="events.length === 0 && userService.isAuth()">
    <div class="col-lg-12">
      <p>Вы еще не принимали участия в забегах</p>
      <button type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="router.navigate(['/']);">Выбрать забег</button>
    </div>
  </div>
  <div class="row" style="margin-top: 15px">
    <div class="col-lg-6" *ngFor="let event of events">
      <!-- START card -->
      <div class="card no-border m-1">
        <div class="padding-15">
          <div class="item-header clearfix">
            <div class="inline">
              <p class="no-margin">
                <strong>{{event.title}}</strong>
              </p>

              <p class="no-margin hint-text">{{event.sub_title}}</p>
            </div>
          </div>
        </div>
        <hr class="no-margin">
        <div>
          <div style="height: 150px; overflow: hidden; display: flex; justify-content: center; align-items: center;">
            <img src="{{event.cover}}" style="height: auto; width: 100%;">
          </div>
        </div>
        <div class="padding-15">
          <p>{{event.description}}</p>

          <button type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="openResult(event.id)">Результат</button>
        </div>
      </div>
      <!-- END card -->
    </div>
  </div>
</pg-container>
<!-- END CONTAINER FLUID -->

