import {Injectable} from "@angular/core";
import {FormControl} from "@angular/forms";

@Injectable()
export class ValidationService {
  static ageValidator(control: FormControl) {
    if (!control.value) {
      return null;
    }

    const date1 = new Date();
    const date2 = new Date(Date.parse(control.value));
    const y = date1.getFullYear() - date2.getFullYear();
    if (y >= 18) {
      return null;
    }

    return { 'invalidAge': true };
  }
}
