import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CasualLayout} from "../../@pages/layouts/casual/casual.component";
import {EventResource} from "../../resources/event.resource";
import {LoaderService} from "../../services/loader.service";
import {UserService} from "../../services/user.service";
@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss']
})
export class MyComponent implements OnInit {
  public events: Array<any> = [];

  constructor(public router: Router,
              public layout: CasualLayout,
              private loaderService: LoaderService,
              public userService: UserService,
              private eventResource: EventResource) {
    if (this.userService.isAuth()) {
      this.getEvents();
    }
  }

  ngOnInit() {}

  private getEvents() {
    const observable = this.eventResource.my();
    const sub = observable.subscribe(
      (response: any) => {
        this.events = response.items;
        console.log(response);
        sub.unsubscribe();
      },
      (error: any) => {
        sub.unsubscribe();
      });
    this.loaderService.add(observable);
  }

  openResult(id) {
    this.router.navigate(['/event/' + id]);
  }
  openPopUp() {
    this.layout.showLogin();
  }
}
