<div class="social-wrapper">
  <div class="social " data-pages="social">
    <!-- START JUMBOTRON -->
    <div class="jumbotron" data-social="cover" coverpage>
      <div class="cover-photo" pg-parallax [ngStyle]="{'background-image': 'url(assets/img/events/comrades/cover.jpg)'}">
      </div>
      <div [ngClass]="{'container': _mode == true, 'container-fluid': _mode !=true}"
        class="container-fixed-lg sm-p-l-0 sm-p-r-0">
        <div class="inner">
          <div class="pull-bottom bottom-left m-b-40 sm-p-l-15">
            <h5 class="text-white no-margin">{{event.sub_title}}</h5>
            <h1 class="text-white no-margin">
              <span class="semi-bold">{{event.title}}</span></h1>
          </div>
        </div>
      </div>
    </div>
    <!-- END JUMBOTRON -->
  </div>
  <!-- /container -->
</div>

<!-- START CONTAINER FLUID -->
<pg-container>
  <div class="row">
    <div class="col-lg-6">
      <!-- START card -->
      <div class="card card-default">
        <div class="card-header ">
          <div class="card-title">
            {{"Description" | translate}}
          </div>
        </div>
        <div class="card-body">
          <div style="padding: 20px 0" *ngIf="event.status === 1">
            {{"start over" | translate}} <countdown [config]="{leftTime: event.left_time, format: translateService.formatCountDown(event.left_time)}" (event)="startEvent($event);"></countdown>
          </div>
          <p>{{event.description}}</p>

          <div class="m-b-10">
            <pg-tag *ngFor="let tag of event.distances">{{tag.name}} км</pg-tag>
          </div>

          <br>
        </div>
      </div>
      <!-- END card -->
    </div>

    <div class="col-lg-6" *ngIf="!userService.isAuth()">
      <!-- START card -->
      <div class="card card-default">
        <div class="card-header ">
          <div class="card-title">
            {{"Login" | translate}}
          </div>
        </div>
        <div class="card-body">
          <h5>
            {{"For registration please login" | translate}}
          </h5>
          <button type="button" class="btn btn-outline-primary btn-lg btn-larger" (click)="layout.showLogin()">
            {{"Login for continue" | translate}}
          </button>
        </div>
      </div>
      <!-- END card -->
    </div>

    <div class="col-lg-6" *ngIf="userService.isAuth()">
      <!-- START card -->
      <div class="card card-default">
        <div class="card-header ">
          <div class="card-title">
            {{"Registration" | translate}}
          </div>

        </div>
        <div class="card-body">

          <div>
            <div class="profile-img-wrapper m-t-5 inline">
              <img alt="" height="35" width="35" src="{{userService.getAvatar()}}">
              <div class="chat-status available"></div>
            </div>
            <div class="inline m-l-10">
              <p class="small hint-text m-t-5">{{user.profile.first_name}} {{user.profile.last_name}}<br> {{user.email}}</p>
            </div>
          </div>
          <button class="btn btn-primary btn-sm" (click)="userService.logout()">{{"Logout" | translate}}</button>

          <div *ngIf="!event.user_distance">
            <h5>
              {{"Fill in the details below" | translate}}
            </h5>
            <form role="form" [formGroup]="registrationForm" (ngSubmit)="registration(registrationForm)">
              <div class="form-group">
                <div class="form-input-group">
                  <label>{{"Date of Birth" | translate}}</label>
                  <pg-datepicker formControlName="birthday" [Format]="'DD.MM.YYYY'"></pg-datepicker>
                </div>
                <span *ngIf="!registrationForm.get('birthday').valid && registrationForm.get('birthday').touched" style="color: red;">
                  {{getBirthdayError(registrationForm.get('birthday').errors)}}
                </span>
              </div>

              <div class="form-group">
                <label>{{"Distance" | translate}}</label>
                <div class="form-group ">
                  <pg-select style="width: 100%;" formControlName="distance" [PlaceHolder]="'Select Option'" AllowClear
                             ShowSearch>
                    <pg-option *ngFor="let option of event.distances" [Label]="option.name" [Value]="option.id"
                               [Disabled]="option.disabled">
                    </pg-option>
                  </pg-select>
                  <span *ngIf="!registrationForm.get('distance').valid && registrationForm.get('distance').touched" style="color: red;">
                    {{"The Distance cannot be empty" | translate}}
                  </span>
                </div>
              </div>

              <button class="btn btn-primary pull-right btn-lg btn-block" type="submit">{{"Registration" | translate}}</button>
            </form>
          </div>
          <div *ngIf="event.user_distance">
            <h5>
              <strong>{{user.profile.first_name}} {{user.profile.last_name}}</strong>
              {{"congratulations! You are registered for a distance of" | translate}} {{event.user_distance.name}} км.
            </h5>
            <p>{{"Registration date" | translate}} {{formatDate(event.user_distance.date_created)}}</p>
          </div>

        </div>
      </div>
      <!-- END card -->
    </div>

  </div>
</pg-container>
<!-- END CONTAINER FLUID -->
